//==============================================================================
// Configuration
//==============================================================================

@import "base/variables";
@import "base/mixins";

//==============================================================================
// Reset
//==============================================================================

@import "base/normalize";
@import "base/reset";

//==============================================================================
// Components
//==============================================================================

@import "components/scaffolding";
@import "components/grid";
@import "components/helpers";
@import "components/buttons";
@import "components/forms";
@import "components/tables";
@import "components/navigation";
@import "components/layout";

//==============================================================================
// Global styles
//==============================================================================

html,
body,
main,
section,
.flex-row,
.flex-large {
	// width: 100%;
	height: 100%;
}

body {
	overflow-x: hidden;
}

main {
	position: relative;
	overflow: hidden;

	&::before {
		content: "";
		display: block;
		position: absolute;
		bottom: 10%;
		right: -10%;
		width: 85%;
		padding-top: (735 / 1386) * 85%;
		background-image: url(../img/knot.svg);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		z-index: 0;

		@include medium-breakpoint {
			top: 35%;
			bottom: auto;
		}
	}
}

section {
	position: relative;
}

.flex-large {
	flex-direction: column;

	img {
		width: 90%;
		max-width: 500px;
	}
}

footer {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;

	&.full-container {	
		padding-top: 1rem;
		padding-bottom: 1rem;
	}

	small {
		font-size: .9rem;
	}
}